define("ember-headlessui/helpers/tag-name-is-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function tagNameIsComponent(_ref) {
    let [as] = _ref;
    return typeof as === 'object';
  }

  var _default = Ember.Helper.helper(tagNameIsComponent);

  _exports.default = _default;
});