define("ember-headlessui/services/dialog-stack-provider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let DialogStackProvider = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class DialogStackProvider extends Ember.Service {
    constructor() {
      super(...arguments);

      _defineProperty(this, "stack", []);
    }

    get dialogIsOpen() {
      return this.stack.length !== 0;
    }

    hasOpenChild(dialog) {
      return this.stack[this.stack.length - 1] !== dialog.guid;
    }

    remove(dialog) {
      let ix = this.stack.findIndex(guid => guid === dialog.guid);
      this.stack.splice(ix, 1);
    }

    push(dialog) {
      this.stack.push(dialog.guid);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "hasOpenChild", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "hasOpenChild"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remove", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "push", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "push"), _class.prototype)), _class));
  _exports.default = DialogStackProvider;
});