define("ember-headlessui/components/menu/item", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield
    (hash
      isActive=this.isActive
      isDisabled=this.isDisabled
      Element=(component
        'menu/item-element'
        guid=this.guid
        isDisabled=@isDisabled
        onMouseOver=this.onMouseOver
        registerItemElement=this.registerItemElement
        unregisterItemElement=this.unregisterItemElement
        onClick=this.onElementClick
      )
    )
  }}
  */
  {
    "id": "VAiscgwl",
    "block": "[[[18,2,[[28,[37,1],null,[[\"isActive\",\"isDisabled\",\"Element\"],[[30,0,[\"isActive\"]],[30,0,[\"isDisabled\"]],[50,\"menu/item-element\",0,null,[[\"guid\",\"isDisabled\",\"onMouseOver\",\"registerItemElement\",\"unregisterItemElement\",\"onClick\"],[[30,0,[\"guid\"]],[30,1],[30,0,[\"onMouseOver\"]],[30,0,[\"registerItemElement\"]],[30,0,[\"unregisterItemElement\"]],[30,0,[\"onElementClick\"]]]]]]]]]]],[\"@isDisabled\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-headlessui/components/menu/item.hbs",
    "isStrictMode": false
  });

  let Item = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class Item extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "guid", `${Ember.guidFor(this)}-tailwindui-menu-item`);

      _defineProperty(this, "element", void 0);

      _initializerDefineProperty(this, "isActive", _descriptor, this);
    }

    get isDisabled() {
      return Boolean(this.args.isDisabled);
    }

    get isEnabled() {
      return !this.isDisabled;
    }

    focus() {
      this.element.focus();
    }

    activate() {
      this.focus();
      this.isActive = true;
    }

    deactivate() {
      this.isActive = false;
    }

    registerItemElement(element) {
      this.element = element;
      this.args.registerItem(this);
    }

    unregisterItemElement() {
      this.element = null;
      this.args.unregisterItem(this);
    }

    onElementClick(event) {
      if (this.isDisabled) return event.preventDefault();
      this.args.closeMenu();
    }

    onMouseOver() {
      if (this.args.isDisabled) return;
      if (this.isActive) return;
      this.args.goToItem(this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isActive", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "focus", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "focus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "activate", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "activate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deactivate", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "deactivate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerItemElement", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "registerItemElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unregisterItemElement", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "unregisterItemElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onElementClick", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onElementClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onMouseOver", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onMouseOver"), _class.prototype)), _class));
  _exports.default = Item;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Item);
});