define("ember-headlessui/components/listbox/-option", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let (element (or @as 'li')) as |Tag|}}
    <Tag
      role='option'
      id={{this.guid}}
      tabindex='-1'
      {{did-insert (fn @registerOptionElement this)}}
      {{on 'focus' (fn @setActiveOption this)}}
      {{on 'mouseover' (fn @setActiveOption this)}}
      {{on 'mouseout' @unsetActiveOption}}
      {{on 'click' this.handleClick}}
      aria-selected={{if this.isSelectedOption 'true'}}
      disabled={{if @disabled true false}}
      ...attributes
    >
      {{yield
        (hash
          active=this.isActiveOption
          selected=this.isSelectedOption
          disabled=(if @disabled true false)
        )
      }}
    </Tag>
  {{/let}}
  */
  {
    "id": "tOY1M5fL",
    "block": "[[[44,[[50,[28,[37,2],[[28,[37,3],[[28,[37,4],[[30,1],\"li\"],null]],null]],null],0,null,[[\"tagName\"],[[28,[37,4],[[30,1],\"li\"],null]]]]],[[[1,\"  \"],[8,[30,2],[[24,\"role\",\"option\"],[16,1,[30,0,[\"guid\"]]],[24,\"tabindex\",\"-1\"],[16,\"aria-selected\",[52,[30,0,[\"isSelectedOption\"]],\"true\"]],[16,\"disabled\",[52,[30,3],true,false]],[17,4],[4,[38,6],[[28,[37,7],[[30,5],[30,0]],null]],null],[4,[38,8],[\"focus\",[28,[37,7],[[30,6],[30,0]],null]],null],[4,[38,8],[\"mouseover\",[28,[37,7],[[30,6],[30,0]],null]],null],[4,[38,8],[\"mouseout\",[30,7]],null],[4,[38,8],[\"click\",[30,0,[\"handleClick\"]]],null]],null,[[\"default\"],[[[[1,\"\\n    \"],[18,8,[[28,[37,10],null,[[\"active\",\"selected\",\"disabled\"],[[30,0,[\"isActiveOption\"]],[30,0,[\"isSelectedOption\"]],[52,[30,3],true,false]]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]],[\"@as\",\"Tag\",\"@disabled\",\"&attrs\",\"@registerOptionElement\",\"@setActiveOption\",\"@unsetActiveOption\",\"&default\"],false,[\"let\",\"component\",\"ensure-safe-component\",\"-element\",\"or\",\"if\",\"did-insert\",\"fn\",\"on\",\"yield\",\"hash\"]]",
    "moduleName": "ember-headlessui/components/listbox/-option.hbs",
    "isStrictMode": false
  });

  let ListboxOptionComponent = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class ListboxOptionComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "guid", _descriptor, this);
    }

    handleClick(e) {
      e.stopPropagation();
      e.preventDefault();
      this.args.setSelectedOption(this, e);
    }

    get isActiveOption() {
      return this.args.activeOptionGuid == this.guid;
    }

    get isSelectedOption() {
      return this.args.selectedOptionGuid == this.guid;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "guid", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return `${Ember.guidFor(this)}-headlessui-listbox-option`;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleClick", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handleClick"), _class.prototype)), _class));
  _exports.default = ListboxOptionComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ListboxOptionComponent);
});