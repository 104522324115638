define("ember-headlessui/components/dialog", ["exports", "@glimmer/component", "ember-headlessui/utils/keyboard", "ember-modifier"], function (_exports, _component, _keyboard, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getPortalRoot = getPortalRoot;
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @isOpen}}
    {{#let (element this.tagName) as |Tag|}}
      {{#in-element this.$portalRoot insertBefore=null}}
        <Tag
          id={{this.guid}}
          role='dialog'
          aria-modal='true'
          ...attributes
          {{headlessui-focus-trap
            focusTrapOptions=(hash
              initialFocus=@initialFocus
              allowOutsideClick=this.allowOutsideClick
              setReturnFocus=this.setReturnFocus
            )
          }}
          {{this.handleEscapeKey @isOpen this.onClose}}
          {{this.lockWindowScroll}}
          {{did-insert (fn this.dialogStackProvider.push this)}}
          {{will-destroy (fn this.dialogStackProvider.remove this)}}
        >
          {{yield
            (hash
              isOpen=@isOpen
              onClose=this.onClose
              Overlay=(component
                'dialog/-overlay'
                guid=this.overlayGuid
                dialogGuid=this.guid
                isOpen=@isOpen
                onClose=this.onClose
              )
              Title=(component
                'dialog/-title'
                guid=this.titleGuid
                dialogGuid=this.guid
                isOpen=@isOpen
              )
              Description=(component
                'dialog/-description'
                guid=this.descriptionGuid
                dialogGuid=this.guid
                isOpen=@isOpen
              )
            )
          }}
        </Tag>
      {{/in-element}}
    {{/let}}
  {{/if}}
  */
  {
    "id": "/fltlLZB",
    "block": "[[[41,[30,1],[[[44,[[50,[28,[37,3],[[28,[37,4],[[30,0,[\"tagName\"]]],null]],null],0,null,[[\"tagName\"],[[30,0,[\"tagName\"]]]]]],[[[40,[[[1,\"      \"],[8,[30,2],[[16,1,[30,0,[\"guid\"]]],[24,\"role\",\"dialog\"],[24,\"aria-modal\",\"true\"],[17,3],[4,[38,6],null,[[\"focusTrapOptions\"],[[28,[37,7],null,[[\"initialFocus\",\"allowOutsideClick\",\"setReturnFocus\"],[[30,4],[30,0,[\"allowOutsideClick\"]],[30,0,[\"setReturnFocus\"]]]]]]]],[4,[30,0,[\"handleEscapeKey\"]],[[30,1],[30,0,[\"onClose\"]]],null],[4,[30,0,[\"lockWindowScroll\"]],null,null],[4,[38,8],[[28,[37,9],[[30,0,[\"dialogStackProvider\",\"push\"]],[30,0]],null]],null],[4,[38,10],[[28,[37,9],[[30,0,[\"dialogStackProvider\",\"remove\"]],[30,0]],null]],null]],null,[[\"default\"],[[[[1,\"\\n        \"],[18,5,[[28,[37,7],null,[[\"isOpen\",\"onClose\",\"Overlay\",\"Title\",\"Description\"],[[30,1],[30,0,[\"onClose\"]],[50,\"dialog/-overlay\",0,null,[[\"guid\",\"dialogGuid\",\"isOpen\",\"onClose\"],[[30,0,[\"overlayGuid\"]],[30,0,[\"guid\"]],[30,1],[30,0,[\"onClose\"]]]]],[50,\"dialog/-title\",0,null,[[\"guid\",\"dialogGuid\",\"isOpen\"],[[30,0,[\"titleGuid\"]],[30,0,[\"guid\"]],[30,1]]]],[50,\"dialog/-description\",0,null,[[\"guid\",\"dialogGuid\",\"isOpen\"],[[30,0,[\"descriptionGuid\"]],[30,0,[\"guid\"]],[30,1]]]]]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],\"%cursor:0%\",[30,0,[\"$portalRoot\"]],null]],[2]]]],[]],null]],[\"@isOpen\",\"Tag\",\"&attrs\",\"@initialFocus\",\"&default\"],false,[\"if\",\"let\",\"component\",\"ensure-safe-component\",\"-element\",\"in-element\",\"headlessui-focus-trap\",\"hash\",\"did-insert\",\"fn\",\"will-destroy\",\"yield\"]]",
    "moduleName": "ember-headlessui/components/dialog.hbs",
    "isStrictMode": false
  });

  /**
   * Expose the element that the `Dialog` should be "slotted" into
   *
   * This is exported _only_ for testing purposes; do not consider this API to be public
   *
   * @private
   */
  function getPortalRoot() {
    const {
      rootElement
    } = {}; // If we looked up a `rootElement` config at build-time, use that; otherwise use the body

    return rootElement ? document.querySelector(rootElement) : document.body;
  }

  let DialogComponent = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class DialogComponent extends _component.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "dialogStackProvider", _descriptor, this);

      _defineProperty(this, "DEFAULT_TAG_NAME", 'div');

      _defineProperty(this, "guid", `${Ember.guidFor(this)}-headlessui-dialog`);

      _defineProperty(this, "$portalRoot", getPortalRoot());

      _defineProperty(this, "outsideClickedElement", null);

      _defineProperty(this, "handleEscapeKey", (0, _emberModifier.modifier)((_element, _ref2) => {
        let [isOpen, onClose] = _ref2;

        let handler = event => {
          if (event.key !== _keyboard.Keys.Escape) return;
          if (!isOpen) return;
          event.preventDefault();
          event.stopPropagation();
          onClose();
        };

        window.addEventListener('keyup', handler);
        return () => {
          window.removeEventListener('keyup', handler);
        };
      }));

      _defineProperty(this, "lockWindowScroll", (0, _emberModifier.modifier)(() => {
        // Opt-out of some other dialog already locked scrolling
        if (this.dialogStackProvider.dialogIsOpen) {
          return;
        }

        let overflow = this.$portalRoot.style.overflow;
        let paddingRight = this.$portalRoot.style.paddingRight; // Setting `overflow: hidden` will suddenly hide the scroll bar on the window, which can cause horizontal
        // layout shifting when the `Dialog` becomes open
        // By applying the width of the scroll bar as padding, we can avoid that layout shift from happening

        let scrollbarWidth = window.innerWidth - this.$portalRoot.clientWidth;
        this.$portalRoot.style.overflow = 'hidden';
        this.$portalRoot.style.paddingRight = `${scrollbarWidth}px`;
        return () => {
          this.$portalRoot.style.overflow = overflow;
          this.$portalRoot.style.paddingRight = paddingRight;
        };
      }));

      let {
        isOpen: _isOpen,
        onClose: _onClose
      } = this.args;

      if (_isOpen === undefined && _onClose === undefined) {
        throw new Error('You have to provide an `isOpen` and an `onClose` prop to the `Dialog` component.');
      }

      if (_isOpen === undefined) {
        throw new Error('You provided an `onClose` prop to the `Dialog`, but forgot an `isOpen` prop.');
      }

      if (_onClose === undefined) {
        throw new Error('You provided an `isOpen` prop to the `Dialog`, but forgot an `onClose` prop.');
      }

      if (Ember.typeOf(_isOpen) !== 'boolean') {
        throw new Error(`You provided an \`isOpen\` prop to the \`Dialog\`, but the value is not a boolean. Received: ${_isOpen}`);
      }

      if (Ember.typeOf(_onClose) !== 'function') {
        throw new Error(`You provided an \`onClose\` prop to the \`Dialog\`, but the value is not a function. Received: ${_onClose}`);
      }
    }

    get tagName() {
      return this.args.as || this.DEFAULT_TAG_NAME;
    }

    get overlayGuid() {
      return `${this.guid}-overlay`;
    }

    get titleGuid() {
      return `${this.guid}-title`;
    }

    get descriptionGuid() {
      return `${this.guid}-description`;
    }

    setReturnFocus(trigger) {
      return this.outsideClickedElement ? this.outsideClickedElement : trigger;
    }

    allowOutsideClick(e) {
      let target = e.target;

      if (target && target.tagName !== 'BODY') {
        this.outsideClickedElement = target;
      }

      this.onClose();
      return true;
    }

    onClose() {
      if (this.dialogStackProvider.hasOpenChild(this)) return;
      this.args.onClose();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dialogStackProvider", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setReturnFocus", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "setReturnFocus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "allowOutsideClick", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "allowOutsideClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onClose"), _class.prototype)), _class));
  _exports.default = DialogComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DialogComponent);
});