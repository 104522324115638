define("ember-headlessui/components/menu/item-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let
    (if (tag-name-is-component @tagName) @tagName (element (or @tagName 'a')))
    as |Tag|
  }}
    <Tag
      id={{@guid}}
      role='menuitem'
      tabindex='-1'
      disabled={{@isDisabled}}
      data-disabled={{@isDisabled}}
      ...attributes
      {{did-insert @registerItemElement}}
      {{on 'mouseover' @onMouseOver}}
      {{on 'click' @onClick}}
      {{will-destroy @unregisterItemElement}}
    >
      {{yield}}
    </Tag>
  {{/let}}
  */
  {
    "id": "2gN/M4oD",
    "block": "[[[44,[[52,[28,[37,2],[[30,1]],null],[30,1],[50,[28,[37,4],[[28,[37,5],[[28,[37,6],[[30,1],\"a\"],null]],null]],null],0,null,[[\"tagName\"],[[28,[37,6],[[30,1],\"a\"],null]]]]]],[[[1,\"  \"],[8,[30,2],[[16,1,[30,3]],[24,\"role\",\"menuitem\"],[24,\"tabindex\",\"-1\"],[16,\"disabled\",[30,4]],[16,\"data-disabled\",[30,4]],[17,5],[4,[38,7],[[30,6]],null],[4,[38,8],[\"mouseover\",[30,7]],null],[4,[38,8],[\"click\",[30,8]],null],[4,[38,9],[[30,9]],null]],null,[[\"default\"],[[[[1,\"\\n    \"],[18,10,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]],[\"@tagName\",\"Tag\",\"@guid\",\"@isDisabled\",\"&attrs\",\"@registerItemElement\",\"@onMouseOver\",\"@onClick\",\"@unregisterItemElement\",\"&default\"],false,[\"let\",\"if\",\"tag-name-is-component\",\"component\",\"ensure-safe-component\",\"-element\",\"or\",\"did-insert\",\"on\",\"will-destroy\",\"yield\"]]",
    "moduleName": "ember-headlessui/components/menu/item-element.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});