define("ember-headlessui/components/switch/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let (element (or @tagName 'button')) as |Tag|}}
    <Tag
      id={{@guid}}
      role='switch'
      tabindex='0'
      aria-checked='{{@isOn}}'
      aria-labelledby={{@labelGuid}}
      ...attributes
      data-test-headlessui-switch-button
      {{on 'click' @onClick}}
      {{on 'keyup' @onKeyUp}}
    >
      {{yield}}
    </Tag>
  {{/let}}
  */
  {
    "id": "iWNnm5j7",
    "block": "[[[44,[[50,[28,[37,2],[[28,[37,3],[[28,[37,4],[[30,1],\"button\"],null]],null]],null],0,null,[[\"tagName\"],[[28,[37,4],[[30,1],\"button\"],null]]]]],[[[1,\"  \"],[8,[30,2],[[16,1,[30,3]],[24,\"role\",\"switch\"],[24,\"tabindex\",\"0\"],[16,\"aria-checked\",[29,[[30,4]]]],[16,\"aria-labelledby\",[30,5]],[17,6],[24,\"data-test-headlessui-switch-button\",\"\"],[4,[38,5],[\"click\",[30,7]],null],[4,[38,5],[\"keyup\",[30,8]],null]],null,[[\"default\"],[[[[1,\"\\n    \"],[18,9,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]],[\"@tagName\",\"Tag\",\"@guid\",\"@isOn\",\"@labelGuid\",\"&attrs\",\"@onClick\",\"@onKeyUp\",\"&default\"],false,[\"let\",\"component\",\"ensure-safe-component\",\"-element\",\"or\",\"on\",\"yield\"]]",
    "moduleName": "ember-headlessui/components/switch/button.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});