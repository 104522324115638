define("ember-headlessui/helpers/transition/applied-class-names", ["exports", "ember-concurrency", "ember-headlessui/utils/transition"], function (_exports, _emberConcurrency, _transition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AppliedClassNamesManager = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let AppliedClassNamesManager = (_class = class AppliedClassNamesManager extends Ember.Helper {
    constructor() {
      super(...arguments);

      _defineProperty(this, "appliedClassNames", new _transition.ClassNameSet());

      _defineProperty(this, "previousShowValue", false);
    }

    /** @type {import('../../components/transition').default} */
    get transition() {
      return this.args[0];
    }
    /**
     * The `Transition` component's `@show` value
     *
     * Represents the desired visibility of the component.
     *
     * @type {boolean}
     */


    get show() {
      return this.args[1];
    }

    get enterClasses() {
      return (0, _transition.splitClassNames)(this.transition.args.enter);
    }

    get enterFromClasses() {
      return (0, _transition.splitClassNames)(this.transition.args.enterFrom);
    }

    get enterToClasses() {
      return (0, _transition.splitClassNames)(this.transition.args.enterTo);
    }

    get enteredClasses() {
      return (0, _transition.splitClassNames)(this.transition.args.entered);
    }

    get leaveClasses() {
      return (0, _transition.splitClassNames)(this.transition.args.leave);
    }

    get leaveFromClasses() {
      return (0, _transition.splitClassNames)(this.transition.args.leaveFrom);
    }

    get leaveToClasses() {
      return (0, _transition.splitClassNames)(this.transition.args.leaveTo);
    }

    *setTransitionClassesTask(base, from, to, entered) {
      this.appliedClassNames.delete(...entered);
      this.appliedClassNames.add(...base, ...from);
      this.recompute(); // Wait for the next tick

      yield (0, _emberConcurrency.timeout)(0);
      this.appliedClassNames.delete(...from);
      this.appliedClassNames.add(...to);
      this.recompute();
      yield (0, _transition.waitForTransition)(this.transition.ownDomNode);
      this.appliedClassNames.delete(...to, ...base);
      this.appliedClassNames.add(...entered);
      this.recompute();
    }

    *transitionToShow() {
      this.transition.args.beforeEnter?.();
      yield this.setTransitionClassesTask.perform(this.enterClasses, this.enterFromClasses, this.enterToClasses, this.enteredClasses);
      this.transition.args.afterEnter?.();
    }

    *transitionToHide() {
      this.transition.args.beforeLeave?.();
      yield this.setTransitionClassesTask.perform(this.leaveClasses, this.leaveFromClasses, this.leaveToClasses, this.enteredClasses);
      this.transition.args.afterLeave?.();
    }

    shouldTransition() {
      return this.show !== this.previousShowValue;
    }

    compute(positionalArgs) {
      // Make sure we have a reference to the args
      this.args = positionalArgs;
      const skip = this.transition.initialRender && !this.transition.args.appear;

      if (!skip && this.shouldTransition()) {
        if (this.show) {
          this.transitionToShow.perform();
        }

        if (!this.show) {
          this.transitionToHide.perform();
        }
      }

      this.previousShowValue = this.show;
      return this.appliedClassNames.toString();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "setTransitionClassesTask", [_emberConcurrency.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "setTransitionClassesTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transitionToShow", [_emberConcurrency.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToShow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transitionToHide", [_emberConcurrency.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToHide"), _class.prototype)), _class);
  _exports.AppliedClassNamesManager = AppliedClassNamesManager;
});