define("ember-headlessui/components/listbox/-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (or @isOpen @static)}}
    {{#let (element (or @as 'ul')) as |Tag|}}
      <Tag
        id={{@guid}}
        tabindex='0'
        role='listbox'
        aria-labelledby={{if
          @hasLabelElement
          (concat @guid '-label')
          (concat @guid '-button')
        }}
        aria-activedescendant={{@activeOptionGuid}}
        aria-orientation='vertical'
        {{did-insert @registerOptionsElement}}
        {{will-destroy @unregisterOptionsElement}}
        {{on 'keypress' @handleKeyPress}}
        {{on 'keydown' @handleKeyDown}}
        {{on 'keyup' @handleKeyUp}}
        {{headlessui-focus-trap
          focusTrapOptions=(hash
            allowOutsideClick=@handleClickOutside fallbackFocus=(concat '#' @guid)
          )
        }}
        ...attributes
      >
        {{yield
          (hash
            Option=(component
              'listbox/-option'
              registerOptionElement=@registerOptionElement
              activeOptionGuid=@activeOptionGuid
              selectedOptionGuid=@selectedOptionGuid
              setActiveOption=@setActiveOption
              unsetActiveOption=@unsetActiveOption
              setSelectedOption=@setSelectedOption
            )
          )
        }}
      </Tag>
    {{/let}}
  {{/if}}
  */
  {
    "id": "HXF9ItKJ",
    "block": "[[[41,[28,[37,1],[[30,1],[30,2]],null],[[[44,[[50,[28,[37,4],[[28,[37,5],[[28,[37,1],[[30,3],\"ul\"],null]],null]],null],0,null,[[\"tagName\"],[[28,[37,1],[[30,3],\"ul\"],null]]]]],[[[1,\"    \"],[8,[30,4],[[16,1,[30,5]],[24,\"tabindex\",\"0\"],[24,\"role\",\"listbox\"],[16,\"aria-labelledby\",[52,[30,6],[28,[37,6],[[30,5],\"-label\"],null],[28,[37,6],[[30,5],\"-button\"],null]]],[16,\"aria-activedescendant\",[30,7]],[24,\"aria-orientation\",\"vertical\"],[17,8],[4,[38,7],[[30,9]],null],[4,[38,8],[[30,10]],null],[4,[38,9],[\"keypress\",[30,11]],null],[4,[38,9],[\"keydown\",[30,12]],null],[4,[38,9],[\"keyup\",[30,13]],null],[4,[38,10],null,[[\"focusTrapOptions\"],[[28,[37,11],null,[[\"allowOutsideClick\",\"fallbackFocus\"],[[30,14],[28,[37,6],[\"#\",[30,5]],null]]]]]]]],null,[[\"default\"],[[[[1,\"\\n      \"],[18,20,[[28,[37,11],null,[[\"Option\"],[[50,\"listbox/-option\",0,null,[[\"registerOptionElement\",\"activeOptionGuid\",\"selectedOptionGuid\",\"setActiveOption\",\"unsetActiveOption\",\"setSelectedOption\"],[[30,15],[30,7],[30,16],[30,17],[30,18],[30,19]]]]]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[4]]]],[]],null]],[\"@isOpen\",\"@static\",\"@as\",\"Tag\",\"@guid\",\"@hasLabelElement\",\"@activeOptionGuid\",\"&attrs\",\"@registerOptionsElement\",\"@unregisterOptionsElement\",\"@handleKeyPress\",\"@handleKeyDown\",\"@handleKeyUp\",\"@handleClickOutside\",\"@registerOptionElement\",\"@selectedOptionGuid\",\"@setActiveOption\",\"@unsetActiveOption\",\"@setSelectedOption\",\"&default\"],false,[\"if\",\"or\",\"let\",\"component\",\"ensure-safe-component\",\"-element\",\"concat\",\"did-insert\",\"will-destroy\",\"on\",\"headlessui-focus-trap\",\"hash\",\"yield\"]]",
    "moduleName": "ember-headlessui/components/listbox/-options.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});