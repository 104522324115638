define("ember-headlessui/components/menu/button", ["exports", "@glimmer/component", "ember-headlessui/utils/keyboard"], function (_exports, _component, _keyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{! template-lint-disable no-down-event-binding }}
  <button
    type='button'
    aria-haspopup={{true}}
    aria-controls={{if @isOpen @itemsGuid}}
    aria-expanded={{@isOpen}}
    id={{@buttonGuid}}
    ...attributes
    {{on 'click' @toggleMenu}}
    {{on 'keydown' this.onKeydown}}
  >
    {{yield}}
  </button>
  */
  {
    "id": "+ZxfeSps",
    "block": "[[[11,\"button\"],[24,4,\"button\"],[16,\"aria-haspopup\",true],[16,\"aria-controls\",[52,[30,1],[30,2]]],[16,\"aria-expanded\",[30,1]],[16,1,[30,3]],[17,4],[4,[38,1],[\"click\",[30,5]],null],[4,[38,1],[\"keydown\",[30,0,[\"onKeydown\"]]],null],[12],[1,\"\\n  \"],[18,6,null],[1,\"\\n\"],[13]],[\"@isOpen\",\"@itemsGuid\",\"@buttonGuid\",\"&attrs\",\"@toggleMenu\",\"&default\"],false,[\"if\",\"on\",\"yield\"]]",
    "moduleName": "ember-headlessui/components/menu/button.hbs",
    "isStrictMode": false
  });

  let Button = (_dec = Ember._action, (_class = class Button extends _component.default {
    onKeydown(event) {
      if (event.target.disabled) return;

      switch (event.key) {
        case _keyboard.Keys.Space:
        case _keyboard.Keys.Enter:
        case _keyboard.Keys.ArrowDown:
          event.preventDefault();
          event.stopPropagation();

          if (this.args.isOpen && event.key === _keyboard.Keys.Enter) {
            this.args.closeMenu();
          } else {
            this.args.openMenu();
            Ember.run.next(() => {
              this.args.goToFirstItem();
            });
          }

          break;

        case 'ArrowUp':
          event.preventDefault();
          event.stopPropagation();
          this.args.openMenu();
          Ember.run.next(() => {
            this.args.goToLastItem();
          });
          break;
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onKeydown", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onKeydown"), _class.prototype)), _class));
  _exports.default = Button;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Button);
});