define("ember-headlessui/components/dialog/-description", ["exports", "@glimmer/component", "ember-modifier"], function (_exports, _component, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let (element this.tagName) as |Tag|}}
    <Tag id={{@guid}} ...attributes {{this.ariaDescribedby @dialogGuid}}>
      {{yield (hash isOpen=@isOpen)}}
    </Tag>
  {{/let}}
  */
  {
    "id": "kYr/afXH",
    "block": "[[[44,[[50,[28,[37,2],[[28,[37,3],[[30,0,[\"tagName\"]]],null]],null],0,null,[[\"tagName\"],[[30,0,[\"tagName\"]]]]]],[[[1,\"  \"],[8,[30,1],[[16,1,[30,2]],[17,3],[4,[30,0,[\"ariaDescribedby\"]],[[30,4]],null]],null,[[\"default\"],[[[[1,\"\\n    \"],[18,6,[[28,[37,5],null,[[\"isOpen\"],[[30,5]]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]],[\"Tag\",\"@guid\",\"&attrs\",\"@dialogGuid\",\"@isOpen\",\"&default\"],false,[\"let\",\"component\",\"ensure-safe-component\",\"-element\",\"yield\",\"hash\"]]",
    "moduleName": "ember-headlessui/components/dialog/-description.hbs",
    "isStrictMode": false
  });

  class DialogDescriptionComponent extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "DEFAULT_TAG_NAME", 'p');

      _defineProperty(this, "ariaDescribedby", (0, _emberModifier.modifier)((element, _ref2) => {
        let [dialogGuid] = _ref2;
        let $dialog = document.getElementById(dialogGuid);
        $dialog.setAttribute('aria-describedby', element.id);
        return () => {
          $dialog.removeAttribute('aria-describedby');
        };
      }));

      let {
        dialogGuid: _dialogGuid
      } = this.args;

      if (_dialogGuid === undefined) {
        throw new Error('<Dialog::-Description /> is missing a parent <Dialog /> component.');
      }
    }

    get tagName() {
      return this.args.as || this.DEFAULT_TAG_NAME;
    }

  }

  _exports.default = DialogDescriptionComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DialogDescriptionComponent);
});