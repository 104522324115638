define("ember-headlessui/components/transition/child", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PARENT_MISSING_ERROR_MESSAGE = _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Transition
    @appear={{@appear}}
    @show={{@show}}
    @unmount={{@unmount}}
    @tagName={{@tagName}}
    @enter={{@enter}}
    @enterFrom={{@enterFrom}}
    @enterTo={{@enterTo}}
    @leave={{@leave}}
    @leaveFrom={{@leaveFrom}}
    @leaveTo={{@leaveTo}}
    @beforeEnter={{@beforeEnter}}
    @afterEnter={{@afterEnter}}
    @beforeLeave={{@beforeLeave}}
    @afterLeave={{@afterLeave}}
    {{did-insert @parent.trackDomNode}}
    {{will-destroy @parent.untrackDomNode}}
    ...attributes
  >
    {{yield}}
  </Transition>
  */
  {
    "id": "tggXJYpS",
    "block": "[[[8,[39,0],[[17,1],[4,[38,1],[[30,16,[\"trackDomNode\"]]],null],[4,[38,2],[[30,16,[\"untrackDomNode\"]]],null]],[[\"@appear\",\"@show\",\"@unmount\",\"@tagName\",\"@enter\",\"@enterFrom\",\"@enterTo\",\"@leave\",\"@leaveFrom\",\"@leaveTo\",\"@beforeEnter\",\"@afterEnter\",\"@beforeLeave\",\"@afterLeave\"],[[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[30,9],[30,10],[30,11],[30,12],[30,13],[30,14],[30,15]]],[[\"default\"],[[[[1,\"\\n  \"],[18,17,null],[1,\"\\n\"]],[]]]]]],[\"&attrs\",\"@appear\",\"@show\",\"@unmount\",\"@tagName\",\"@enter\",\"@enterFrom\",\"@enterTo\",\"@leave\",\"@leaveFrom\",\"@leaveTo\",\"@beforeEnter\",\"@afterEnter\",\"@beforeLeave\",\"@afterLeave\",\"@parent\",\"&default\"],false,[\"transition\",\"did-insert\",\"will-destroy\",\"yield\"]]",
    "moduleName": "ember-headlessui/components/transition/child.hbs",
    "isStrictMode": false
  });

  class TransitionChildComponent extends _component.default {
    constructor() {
      super(...arguments);
      let {
        parent
      } = this.args;
      (false && !(typeof parent !== 'undefined') && Ember.assert(PARENT_MISSING_ERROR_MESSAGE, typeof parent !== 'undefined'));
    }

  }

  _exports.default = TransitionChildComponent;
  const PARENT_MISSING_ERROR_MESSAGE = '<Transition::Child /> is missing a parent <Transition /> component.';
  _exports.PARENT_MISSING_ERROR_MESSAGE = PARENT_MISSING_ERROR_MESSAGE;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TransitionChildComponent);
});