define("ember-headlessui/components/listbox/-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let (element (or @as 'button')) as |Tag|}}
    <Tag
      role={{if @role @role 'button'}}
      type={{if @type @type 'button'}}
      id='{{@guid}}-button'
      aria-haspopup='listbox'
      aria-controls={{@guid}}
      aria-labelledby='{{@guid}}-label {{@guid}}-button'
      {{on 'click' @handleButtonClick}}
      {{on 'keydown' @handleKeyDown}}
      {{on 'keyup' @handleKeyUp}}
      {{on 'keypress' @handleKeyPress}}
      aria-expanded={{unless @isDisabled (if @isOpen 'true' 'false')}}
      disabled={{@isDisabled}}
      {{did-insert @registerButtonElement}}
      {{will-destroy @unregisterButtonElement}}
      ...attributes
    >
      {{yield}}
    </Tag>
  {{/let}}
  */
  {
    "id": "o26sc7sS",
    "block": "[[[44,[[50,[28,[37,2],[[28,[37,3],[[28,[37,4],[[30,1],\"button\"],null]],null]],null],0,null,[[\"tagName\"],[[28,[37,4],[[30,1],\"button\"],null]]]]],[[[1,\"  \"],[8,[30,2],[[16,\"role\",[52,[30,3],[30,3],\"button\"]],[16,4,[52,[30,4],[30,4],\"button\"]],[16,1,[29,[[30,5],\"-button\"]]],[24,\"aria-haspopup\",\"listbox\"],[16,\"aria-controls\",[30,5]],[16,\"aria-labelledby\",[29,[[30,5],\"-label \",[30,5],\"-button\"]]],[16,\"aria-expanded\",[52,[51,[30,6]],[52,[30,7],\"true\",\"false\"]]],[16,\"disabled\",[30,6]],[17,8],[4,[38,7],[\"click\",[30,9]],null],[4,[38,7],[\"keydown\",[30,10]],null],[4,[38,7],[\"keyup\",[30,11]],null],[4,[38,7],[\"keypress\",[30,12]],null],[4,[38,8],[[30,13]],null],[4,[38,9],[[30,14]],null]],null,[[\"default\"],[[[[1,\"\\n    \"],[18,15,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]],[\"@as\",\"Tag\",\"@role\",\"@type\",\"@guid\",\"@isDisabled\",\"@isOpen\",\"&attrs\",\"@handleButtonClick\",\"@handleKeyDown\",\"@handleKeyUp\",\"@handleKeyPress\",\"@registerButtonElement\",\"@unregisterButtonElement\",\"&default\"],false,[\"let\",\"component\",\"ensure-safe-component\",\"-element\",\"or\",\"if\",\"unless\",\"on\",\"did-insert\",\"will-destroy\",\"yield\"]]",
    "moduleName": "ember-headlessui/components/listbox/-button.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});