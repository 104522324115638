define("ember-headlessui/components/dialog/-overlay", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let (element this.tagName) as |Tag|}}
    <Tag id={{@guid}} role='button' ...attributes {{on 'click' this.handleClick}}>
      {{yield (hash isOpen=@isOpen)}}
    </Tag>
  {{/let}}
  */
  {
    "id": "YKmz4Y5l",
    "block": "[[[44,[[50,[28,[37,2],[[28,[37,3],[[30,0,[\"tagName\"]]],null]],null],0,null,[[\"tagName\"],[[30,0,[\"tagName\"]]]]]],[[[1,\"  \"],[8,[30,1],[[16,1,[30,2]],[24,\"role\",\"button\"],[17,3],[4,[38,4],[\"click\",[30,0,[\"handleClick\"]]],null]],null,[[\"default\"],[[[[1,\"\\n    \"],[18,5,[[28,[37,6],null,[[\"isOpen\"],[[30,4]]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]],[\"Tag\",\"@guid\",\"&attrs\",\"@isOpen\",\"&default\"],false,[\"let\",\"component\",\"ensure-safe-component\",\"-element\",\"on\",\"yield\",\"hash\"]]",
    "moduleName": "ember-headlessui/components/dialog/-overlay.hbs",
    "isStrictMode": false
  });

  let DialogOverlayComponent = (_dec = Ember._action, (_class = class DialogOverlayComponent extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "DEFAULT_TAG_NAME", 'div');

      let {
        dialogGuid
      } = this.args;

      if (dialogGuid === undefined) {
        throw new Error('<Dialog::-Overlay /> is missing a parent <Dialog /> component.');
      }
    }

    get tagName() {
      return this.args.as || this.DEFAULT_TAG_NAME;
    }

    handleClick(event) {
      let {
        onClose
      } = this.args;
      event.preventDefault();
      event.stopPropagation();
      onClose();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "handleClick", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "handleClick"), _class.prototype)), _class));
  _exports.default = DialogOverlayComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DialogOverlayComponent);
});