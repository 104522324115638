define("ember-headlessui/helpers/transition/transition-visibility", ["exports", "ember-concurrency", "ember-headlessui/utils/transition"], function (_exports, _emberConcurrency, _transition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TransitionVisibilityManager = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let TransitionVisibilityManager = (_class = class TransitionVisibilityManager extends Ember.Helper {
    constructor() {
      super(...arguments);

      _defineProperty(this, "value", false);

      _defineProperty(this, "previousShowValue", undefined);
    }

    /**
     * All of the DOM nodes related to a transition
     *
     * Includes the parent element plus any children
     *
     * @type {Set<HTMLElement>}
     */
    get domNodes() {
      return this.args[0];
    }
    /**
     * The `Transition` component's `this.args.show` value
     *
     * Represents the desired visibility of the component.
     *
     * @type {boolean}
     */


    get show() {
      return this.args[1];
    }

    isInitialRender() {
      return typeof this.previousShowValue === 'undefined';
    }

    shouldTransition() {
      return this.show !== this.previousShowValue;
    }

    compute(positionalArgs) {
      this.args = positionalArgs;

      if (this.isInitialRender()) {
        this.value = this.show;
      } else if (this.shouldTransition()) {
        if (this.show) {
          this.value = true;
        } else {
          this.unmountAfterTransitionTask.perform();
        }
      }

      this.previousShowValue = this.show;
      return this.value;
    }

    *unmountAfterTransitionTask() {
      // Wait for the next tick, to ensure that the class name string has time to re-build
      yield (0, _emberConcurrency.timeout)(0); // Wait for all Transition DOM elements to finish transitioning (parent and children)

      yield (0, _emberConcurrency.all)(Array.from(this.domNodes).map(domNode => (0, _transition.waitForTransition)(domNode)));
      this.value = false;
      this.recompute();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "unmountAfterTransitionTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "unmountAfterTransitionTask"), _class.prototype)), _class);
  _exports.TransitionVisibilityManager = TransitionVisibilityManager;
});