define("ember-headlessui/components/menu", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield
    (hash
      isOpen=this.isOpen
      open=this.open
      close=this.close
      Button=(component
        'menu/button'
        buttonGuid=this.buttonGuid
        itemsGuid=this.itemsGuid
        isOpen=this.isOpen
        openMenu=this.open
        closeMenu=this.close
        toggleMenu=this.toggle
        goToFirstItem=this.goToFirstItem
        goToLastItem=this.goToLastItem
        goToNextItem=this.goToNextItem
        goToPreviousItem=this.goToPreviousItem
      )
      Items=(component
        'menu/items'
        buttonGuid=this.buttonGuid
        itemsGuid=this.itemsGuid
        isOpen=this.isOpen
        closeMenu=this.close
        activeItem=this.activeItem
        registerItem=this.registerItem
        unregisterItem=this.unregisterItem
        goToFirstItem=this.goToFirstItem
        goToLastItem=this.goToLastItem
        goToNextItem=this.goToNextItem
        goToPreviousItem=this.goToPreviousItem
        goToItem=this.goToItem
        search=(perform this.searchTask)
      )
    )
  }}
  */
  {
    "id": "ZvbAzFuR",
    "block": "[[[18,1,[[28,[37,1],null,[[\"isOpen\",\"open\",\"close\",\"Button\",\"Items\"],[[30,0,[\"isOpen\"]],[30,0,[\"open\"]],[30,0,[\"close\"]],[50,\"menu/button\",0,null,[[\"buttonGuid\",\"itemsGuid\",\"isOpen\",\"openMenu\",\"closeMenu\",\"toggleMenu\",\"goToFirstItem\",\"goToLastItem\",\"goToNextItem\",\"goToPreviousItem\"],[[30,0,[\"buttonGuid\"]],[30,0,[\"itemsGuid\"]],[30,0,[\"isOpen\"]],[30,0,[\"open\"]],[30,0,[\"close\"]],[30,0,[\"toggle\"]],[30,0,[\"goToFirstItem\"]],[30,0,[\"goToLastItem\"]],[30,0,[\"goToNextItem\"]],[30,0,[\"goToPreviousItem\"]]]]],[50,\"menu/items\",0,null,[[\"buttonGuid\",\"itemsGuid\",\"isOpen\",\"closeMenu\",\"activeItem\",\"registerItem\",\"unregisterItem\",\"goToFirstItem\",\"goToLastItem\",\"goToNextItem\",\"goToPreviousItem\",\"goToItem\",\"search\"],[[30,0,[\"buttonGuid\"]],[30,0,[\"itemsGuid\"]],[30,0,[\"isOpen\"]],[30,0,[\"close\"]],[30,0,[\"activeItem\"]],[30,0,[\"registerItem\"]],[30,0,[\"unregisterItem\"]],[30,0,[\"goToFirstItem\"]],[30,0,[\"goToLastItem\"]],[30,0,[\"goToNextItem\"]],[30,0,[\"goToPreviousItem\"]],[30,0,[\"goToItem\"]],[28,[37,3],[[30,0,[\"searchTask\"]]],null]]]]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"perform\"]]",
    "moduleName": "ember-headlessui/components/menu.hbs",
    "isStrictMode": false
  });

  let Menu = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, (_class = class Menu extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "guid", `${Ember.guidFor(this)}-tailwindui-menu`);

      _initializerDefineProperty(this, "isOpen", _descriptor, this);

      _initializerDefineProperty(this, "items", _descriptor2, this);

      _initializerDefineProperty(this, "activeItem", _descriptor3, this);

      _initializerDefineProperty(this, "searchTerm", _descriptor4, this);
    }

    get activeItemIndex() {
      return this.items.indexOf(this.activeItem);
    }

    toggle() {
      if (this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    }

    open() {
      this.isOpen = true;
    }

    close() {
      this.isOpen = false;
    }

    goToFirstItem() {
      let firstItem = this.items.find(item => item.isEnabled);

      this._setActiveItem(firstItem);
    }

    goToLastItem() {
      let lastItem = this.items.slice().reverse().find(item => item.isEnabled);

      this._setActiveItem(lastItem);
    }

    goToPreviousItem() {
      let previousItem = this.items.slice().reverse().find((item, index) => {
        if (this.activeItemIndex !== -1 && this.items.length - index - 1 >= this.activeItemIndex) {
          return false;
        }

        return item.isEnabled;
      });

      this._setActiveItem(previousItem);
    }

    goToNextItem() {
      let nextItem = this.items.find((item, index) => {
        if (index <= this.activeItemIndex) {
          return false;
        }

        return item.isEnabled;
      });

      this._setActiveItem(nextItem);
    }

    goToItem(item) {
      this._setActiveItem(item);
    }

    *searchTask(nextCharacter) {
      this.searchTerm += nextCharacter.toLowerCase();
      const searchResult = this.items.find(item => {
        const textValue = item.element.textContent.toLowerCase().trim();
        return item.isEnabled && textValue.startsWith(this.searchTerm);
      });

      if (searchResult) {
        this._setActiveItem(searchResult);
      }

      yield (0, _emberConcurrency.timeout)(350);
      this.searchTerm = '';
    }

    registerItem(item) {
      let {
        items
      } = this;
      items.push(item);
      this.items = items;
    }

    unregisterItem(item) {
      let {
        items
      } = this;
      let index = items.indexOf(item);
      items.splice(index, 1);
      this.items = items;
    }

    _setActiveItem(item) {
      if (item) {
        this.activeItem = item;
        this.items.forEach(item => item.deactivate());
        this.activeItem.activate();
        this.itemsElement.focus();
      }
    }

    get itemsGuid() {
      return `${this.guid}-items`;
    }

    get itemsElement() {
      return document.getElementById(this.itemsGuid);
    }

    get buttonGuid() {
      return `${this.guid}-button`;
    }

    get buttonElement() {
      return document.getElementById(this.buttonGuid);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isOpen", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "items", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "activeItem", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "searchTerm", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggle", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "open", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "open"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goToFirstItem", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "goToFirstItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goToLastItem", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "goToLastItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goToPreviousItem", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "goToPreviousItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goToNextItem", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "goToNextItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goToItem", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "goToItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerItem", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "registerItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unregisterItem", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "unregisterItem"), _class.prototype)), _class));
  _exports.default = Menu;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Menu);
});