define("ember-headlessui/utils/transition", ["exports", "ember-concurrency", "tracked-maps-and-sets"], function (_exports, _emberConcurrency, _trackedMapsAndSets) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.waitForTransition = waitForTransition;
  _exports.splitClassNames = splitClassNames;
  _exports.ClassNameSet = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * Create a promise that resolves after the transition timeout for a node
   *
   * Extracted from HeadlessUI here:
   *
   * https://github.com/tailwindlabs/headlessui/blob/b961a189d57fa24af2a0bd0a6d73fa44e1d34529/packages/%40headlessui-react/src/components/transitions/utils/transition.ts#L17
   *
   * @param {HTMLElement} node
   * @return {Promise<void>}
   */
  async function waitForTransition(node) {
    // Safari returns a comma separated list of values, so let's sort them and take the highest value.
    let {
      transitionDuration,
      transitionDelay
    } = getComputedStyle(node);
    let [durationMs, delaysMs] = [transitionDuration, transitionDelay].map(value => {
      let [resolvedValue = 0] = value.split(',') // Remove falsy we can't work with
      .filter(Boolean) // Values are returned as `0.3s` or `75ms`
      .map(v => v.includes('ms') ? parseFloat(v) : parseFloat(v) * 1000).sort((a, z) => z - a);
      return resolvedValue;
    });

    if (durationMs !== 0) {
      await (0, _emberConcurrency.timeout)(durationMs + delaysMs);
    }
  }

  function splitClassNames() {
    let classString = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return classString.split(' ').map(part => part.trim()).filter(part => part.trim().length > 1);
  }

  class ClassNameSet {
    constructor() {
      _defineProperty(this, "data", new _trackedMapsAndSets.TrackedSet());
    }

    add() {
      for (var _len = arguments.length, values = new Array(_len), _key = 0; _key < _len; _key++) {
        values[_key] = arguments[_key];
      }

      for (let value of values) {
        this.data.add(value);
      }
    }

    delete() {
      for (var _len2 = arguments.length, values = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        values[_key2] = arguments[_key2];
      }

      for (let value of values) {
        this.data.delete(value);
      }
    }

    toString() {
      return Array.from(this.data).join(' ');
    }

  }

  _exports.ClassNameSet = ClassNameSet;
});