define("ember-headlessui/components/menu/items", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{! template-lint-disable no-down-event-binding }}
  {{#if @isOpen}}
    <div
      id={{@itemsGuid}}
      aria-labelledby={{@buttonGuid}}
      aria-activedescendant={{@activeItem.element.id}}
      tabindex='-1'
      role='menu'
      ...attributes
      {{on 'keydown' this.onKeydown}}
      {{headlessui-focus-trap
        focusTrapOptions=(hash
          allowOutsideClick=this.allowClickOutsideFocusTrap
          clickOutsideDeactivates=this.clickOutsideFocusTrapDeactivates
          fallbackFocus=this.menuItemsElementSelector
          initialFocus=this.menuItemsElementSelector
          onDeactivate=@closeMenu
        )
      }}
    >
      {{yield
        (hash
          Item=(component
            'menu/item'
            registerItem=@registerItem
            unregisterItem=@unregisterItem
            goToItem=@goToItem
            closeMenu=@closeMenu
          )
        )
      }}
    </div>
  {{/if}}
  */
  {
    "id": "73Ry8dR8",
    "block": "[[[41,[30,1],[[[1,\"  \"],[11,0],[16,1,[30,2]],[16,\"aria-labelledby\",[30,3]],[16,\"aria-activedescendant\",[30,4,[\"element\",\"id\"]]],[24,\"tabindex\",\"-1\"],[24,\"role\",\"menu\"],[17,5],[4,[38,1],[\"keydown\",[30,0,[\"onKeydown\"]]],null],[4,[38,2],null,[[\"focusTrapOptions\"],[[28,[37,3],null,[[\"allowOutsideClick\",\"clickOutsideDeactivates\",\"fallbackFocus\",\"initialFocus\",\"onDeactivate\"],[[30,0,[\"allowClickOutsideFocusTrap\"]],[30,0,[\"clickOutsideFocusTrapDeactivates\"]],[30,0,[\"menuItemsElementSelector\"]],[30,0,[\"menuItemsElementSelector\"]],[30,6]]]]]]],[12],[1,\"\\n    \"],[18,10,[[28,[37,3],null,[[\"Item\"],[[50,\"menu/item\",0,null,[[\"registerItem\",\"unregisterItem\",\"goToItem\",\"closeMenu\"],[[30,7],[30,8],[30,9],[30,6]]]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@isOpen\",\"@itemsGuid\",\"@buttonGuid\",\"@activeItem\",\"&attrs\",\"@closeMenu\",\"@registerItem\",\"@unregisterItem\",\"@goToItem\",\"&default\"],false,[\"if\",\"on\",\"headlessui-focus-trap\",\"hash\",\"yield\",\"component\"]]",
    "moduleName": "ember-headlessui/components/menu/items.hbs",
    "isStrictMode": false
  });

  let Items = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class Items extends _component.default {
    get menuItemsElementSelector() {
      return `#${this.args.itemsGuid}`;
    }

    onKeydown(event) {
      switch (event.key) {
        // Ref: https://www.w3.org/TR/wai-aria-practices-1.2/#keyboard-interaction-12
        case 'Enter':
          if (this.args.activeItem) {
            this.args.activeItem.element.click();
          }

          this.args.closeMenu();
          break;

        case 'ArrowDown':
          event.preventDefault();
          event.stopPropagation();
          return this.args.goToNextItem();

        case 'ArrowUp':
          event.preventDefault();
          event.stopPropagation();
          return this.args.goToPreviousItem();

        default:
          if (event.key.length === 1) {
            return this.args.search(event.key);
          }

      }
    }

    clickInsideMenuTrigger(event) {
      const buttonElement = document.getElementById(this.args.buttonGuid); // The `buttonElement` could not exist if the element has been removed from the DOM

      return buttonElement ? buttonElement.contains(event.target) : false;
    }

    allowClickOutsideFocusTrap(event) {
      return this.clickInsideMenuTrigger(event);
    }

    clickOutsideFocusTrapDeactivates(event) {
      return !this.clickInsideMenuTrigger(event);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onKeydown", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onKeydown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "allowClickOutsideFocusTrap", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "allowClickOutsideFocusTrap"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clickOutsideFocusTrapDeactivates", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "clickOutsideFocusTrapDeactivates"), _class.prototype)), _class));
  _exports.default = Items;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Items);
});