define("ember-headlessui/components/switch", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    ...attributes
    data-test-headlessui-switch
    data-test-headlessui-switch-is-on={{@isOn}}
  >
    {{yield
      (hash
        isOn=@isOn
        Label=(component
          'switch/label' guid=this.labelGuid onClick=this.onLabelClick
        )
        Button=(component
          'switch/button'
          guid=this.buttonGuid
          labelGuid=this.labelGuid
          isOn=@isOn
          onClick=this.onButtonClick
          onKeyUp=this.onButtonKeyUp
        )
      )
    }}
  </div>
  */
  {
    "id": "W/Gjrr8I",
    "block": "[[[11,0],[17,1],[24,\"data-test-headlessui-switch\",\"\"],[16,\"data-test-headlessui-switch-is-on\",[30,2]],[12],[1,\"\\n  \"],[18,3,[[28,[37,1],null,[[\"isOn\",\"Label\",\"Button\"],[[30,2],[50,\"switch/label\",0,null,[[\"guid\",\"onClick\"],[[30,0,[\"labelGuid\"]],[30,0,[\"onLabelClick\"]]]]],[50,\"switch/button\",0,null,[[\"guid\",\"labelGuid\",\"isOn\",\"onClick\",\"onKeyUp\"],[[30,0,[\"buttonGuid\"]],[30,0,[\"labelGuid\"]],[30,2],[30,0,[\"onButtonClick\"]],[30,0,[\"onButtonKeyUp\"]]]]]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@isOn\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-headlessui/components/switch.hbs",
    "isStrictMode": false
  });

  let Switch = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class Switch extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "guid", `${Ember.guidFor(this)}-tailwindui-switch`);

      _defineProperty(this, "labelGuid", `${this.guid}-label`);

      _defineProperty(this, "buttonGuid", `${this.guid}-button`);
    }

    onLabelClick() {
      let buttonElement = document.getElementById(this.buttonGuid);
      buttonElement.click();
      buttonElement.focus();
    }

    onButtonClick(event) {
      event.preventDefault();

      this._toggle();
    }

    onButtonKeyUp(event) {
      event.preventDefault();

      if (event.key === ' ') {
        event.preventDefault();

        this._toggle();
      }
    }

    _toggle() {
      this.args.onUpdate(!this.args.isOn);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onLabelClick", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onLabelClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onButtonClick", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onButtonClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onButtonKeyUp", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onButtonKeyUp"), _class.prototype)), _class));
  _exports.default = Switch;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Switch);
});