define("ember-headlessui/components/transition", ["exports", "@glimmer/component", "ember-headlessui/helpers/transition"], function (_exports, _component, _transition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ARG_SHOW_MISSING_ERROR_MESSAGE = _exports.default = void 0;

  var _dec, _dec2, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.componentIsMounted}}
    {{#let (element this.tagName) as |Tag|}}
      <Tag
        class={{this.transitionClassNames}}
        hidden={{this.componentIsHidden}}
        style={{if this.componentIsHidden 'display: none'}}
        {{did-insert (set this 'ownDomNode')}}
        {{did-insert this.trackDomNode}}
        {{will-destroy this.untrackDomNode}}
        ...attributes
      >
        {{yield
          (hash
            Child=(component
              'transition/child' parent=this show=@show unmount=@unmount
            )
          )
        }}
      </Tag>
    {{/let}}
  {{/if}}
  */
  {
    "id": "g9X/Z5pe",
    "block": "[[[41,[30,0,[\"componentIsMounted\"]],[[[44,[[50,[28,[37,3],[[28,[37,4],[[30,0,[\"tagName\"]]],null]],null],0,null,[[\"tagName\"],[[30,0,[\"tagName\"]]]]]],[[[1,\"    \"],[8,[30,1],[[16,0,[30,0,[\"transitionClassNames\"]]],[16,\"hidden\",[30,0,[\"componentIsHidden\"]]],[16,5,[52,[30,0,[\"componentIsHidden\"]],\"display: none\"]],[17,2],[4,[38,5],[[28,[37,6],[[30,0],\"ownDomNode\"],null]],null],[4,[38,5],[[30,0,[\"trackDomNode\"]]],null],[4,[38,7],[[30,0,[\"untrackDomNode\"]]],null]],null,[[\"default\"],[[[[1,\"\\n      \"],[18,5,[[28,[37,9],null,[[\"Child\"],[[50,\"transition/child\",0,null,[[\"parent\",\"show\",\"unmount\"],[[30,0],[30,3],[30,4]]]]]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1]]]],[]],null]],[\"Tag\",\"&attrs\",\"@show\",\"@unmount\",\"&default\"],false,[\"if\",\"let\",\"component\",\"ensure-safe-component\",\"-element\",\"did-insert\",\"set\",\"will-destroy\",\"yield\",\"hash\"]]",
    "moduleName": "ember-headlessui/components/transition.hbs",
    "isStrictMode": false
  });

  let TransitionComponent = (_dec = Ember._action, _dec2 = Ember._action, (_class = class TransitionComponent extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "allTransitionDomNodes", new Set());

      _defineProperty(this, "transitionVisibilityManager", Ember._invokeHelper(this, _transition.TransitionVisibilityManager, () => ({
        positional: [this.allTransitionDomNodes, this.args.show]
      })));

      _defineProperty(this, "initialRender", true);

      _defineProperty(this, "ownDomNode", void 0);

      _defineProperty(this, "appliedClassNamesManager", Ember._invokeHelper(this, _transition.AppliedClassNamesManager, () => ({
        positional: [this, this.args.show]
      })));

      let {
        show
      } = this.args;
      (false && !(typeof show === 'boolean') && Ember.assert(ARG_SHOW_MISSING_ERROR_MESSAGE, typeof show === 'boolean'));
      Ember.run.schedule('afterRender', () => {
        this.initialRender = false;
      });
    }

    get tagName() {
      return this.args.tagName ?? 'div';
    }

    get unmount() {
      return this.args.unmount ?? true;
    }
    /* === Component Visibility Management === */


    get componentIsVisible() {
      return Ember._cacheGetValue(this.transitionVisibilityManager);
    }

    get componentIsMounted() {
      return this.unmount ? this.componentIsVisible : true;
    }

    get componentIsHidden() {
      return this.unmount ? false : !this.componentIsVisible;
    }

    trackDomNode(element) {
      this.allTransitionDomNodes.add(element);
    }

    untrackDomNode(element) {
      this.allTransitionDomNodes.delete(element);
    }
    /* === Transition Class Name Management === */


    get transitionClassNames() {
      return Ember._cacheGetValue(this.appliedClassNamesManager);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "trackDomNode", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "trackDomNode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "untrackDomNode", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "untrackDomNode"), _class.prototype)), _class));
  _exports.default = TransitionComponent;
  const ARG_SHOW_MISSING_ERROR_MESSAGE = 'You have to provide a `show` prop to the `Transition` component';
  _exports.ARG_SHOW_MISSING_ERROR_MESSAGE = ARG_SHOW_MISSING_ERROR_MESSAGE;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TransitionComponent);
});