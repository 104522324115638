define("ember-headlessui/components/switch/label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <label
    id={{@guid}}
    ...attributes
    data-test-headlessui-switch-label
    {{on 'click' @onClick}}
  >
    {{yield}}
  </label>
  */
  {
    "id": "Xrb3BjwQ",
    "block": "[[[11,\"label\"],[16,1,[30,1]],[17,2],[24,\"data-test-headlessui-switch-label\",\"\"],[4,[38,0],[\"click\",[30,3]],null],[12],[1,\"\\n  \"],[18,4,null],[1,\"\\n\"],[13]],[\"@guid\",\"&attrs\",\"@onClick\",\"&default\"],false,[\"on\",\"yield\"]]",
    "moduleName": "ember-headlessui/components/switch/label.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});