define("ember-headlessui/modifiers/headlessui-focus-trap", ["exports", "ember-modifier", "focus-trap"], function (_exports, _emberModifier, _focusTrap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)(function headlessuiFocusTrap(element, params) {
    let {
      focusTrapOptions
    } = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    let trap = (0, _focusTrap.createFocusTrap)(element, focusTrapOptions);
    trap.activate();
    return () => {
      trap.deactivate();
    };
  });

  _exports.default = _default;
});