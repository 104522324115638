define("ember-headlessui/components/listbox/-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let (element (or @as 'label')) as |Tag|}}
    <Tag
      id='{{@guid}}-label'
      for='{{@guid}}-button'
      {{on 'click' @handleLabelClick}}
      {{did-insert @registerLabelElement}}
      ...attributes
    >
      {{yield}}
    </Tag>
  {{/let}}
  */
  {
    "id": "ba3IKFqS",
    "block": "[[[44,[[50,[28,[37,2],[[28,[37,3],[[28,[37,4],[[30,1],\"label\"],null]],null]],null],0,null,[[\"tagName\"],[[28,[37,4],[[30,1],\"label\"],null]]]]],[[[1,\"  \"],[8,[30,2],[[16,1,[29,[[30,3],\"-label\"]]],[16,\"for\",[29,[[30,3],\"-button\"]]],[17,4],[4,[38,5],[\"click\",[30,5]],null],[4,[38,6],[[30,6]],null]],null,[[\"default\"],[[[[1,\"\\n    \"],[18,7,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]],[\"@as\",\"Tag\",\"@guid\",\"&attrs\",\"@handleLabelClick\",\"@registerLabelElement\",\"&default\"],false,[\"let\",\"component\",\"ensure-safe-component\",\"-element\",\"or\",\"on\",\"did-insert\",\"yield\"]]",
    "moduleName": "ember-headlessui/components/listbox/-label.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});